import React, { useState } from 'react';

import Text from 'components/UI/Text/Text';
import Section from 'components/UI/Section/Section';
import Card from 'components/UI/Card/Card';
import Filters from 'components/UI/Filters/Filters';

const tickerStyles = {
  fontSize: "80%",
  position: "absolute",
  top: "0",
  right: "0",
  color: "#a4a4a4",
  fontWeight: "100",
};
const Ticker = ({ count }) => (
  <span style={tickerStyles}>{count}</span>
);

const List = ({ clientList, partnerList }) => {
  // console.log("hello", props);
  const distinct = [];
  const distinctPartnerIndustries = [];

  clientList.map(({ node }) => {
    if (!distinct.some(({ industry }) => industry === node.industry)) {
      distinct.push(node);
    }
  });
  partnerList.map(({ node }) => {
    if (!distinctPartnerIndustries.some(({ industry }) => industry === node.industry)) {
      distinctPartnerIndustries.push(node);
    }
  });

  const filtersConfig = [{
    name: 'services',
    label: 'Vertical',
    options: distinct.map(({ industry }) => ({
      value: industry,
      title: industry,
    })),
  }];

  const partnersFiltersConfig = [{
    name: 'services',
    label: 'Vertical',
    options: distinctPartnerIndustries.map(({ industry }) => ({
      value: industry,
      title: industry,
    })),
  }];

  const initialVals = filtersConfig.reduce(
    (acc, { name, options }) => ({
      ...acc,
      [name]: 'all',
    }),
    {},
  );

  const [filters, setFilters] = useState(initialVals);
  const filteredClients = clientList.filter(
    ({ node: { industry } }) => ['all', industry].includes(filters.services),
  );
  const filteredPartners = partnerList.filter(
    ({ node: { industry } }) => ['all', industry].includes(filters.services),
  );

  return (
    <Section>
    <Text as="h2" line stack>Ecosystem</Text>
    <Text as="h3" line>
      Clients <Ticker count={filteredClients.length}/>
    </Text>
    <Filters config={filtersConfig} onChange={setFilters} />
    <ul>
      {filteredClients.map(({ node: { logo, name, clientUrl } }) => (
        <Card
          key={name}
          small
          icon={logo.file.url}
          alt={name}
          href={clientUrl}
          bgColor="#fff"
        />
      ))}
    </ul>
    <Text as="h3" line>
      Partners <Ticker count={filteredClients.length}/>
    </Text>
    <Filters config={partnersFiltersConfig} onChange={setFilters} />
    <ul>
      {filteredPartners.map(({ node: { logo, name, partnerUrl } }) => (
        <Card
          key={name}
          small
          icon={logo.file.url}
          alt={name}
          href={partnerUrl}
          bgColor="#fff"
        />
      ))}
    </ul>

    {/* <ul>
      {partnerList.map(partner => (
        <Card
          key={partner.node.name}
          small
          icon={partner.node.logo.file.url}
          alt={partner.node.name}
          bgColor="#fff"
        />
      ))}
    </ul> */}

  </Section>)
};

export default List;
