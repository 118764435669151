import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout/Layout';
import List from 'components/Clients/List';

const Clients = ({ data }) => (
  <Layout page="clients">
    <List
      clientList={data.allContentfulClients.edges}
      partnerList={data.allContentfulPartners.edges}
    />
  </Layout>
);

export default Clients;

export const query = graphql`
  query {
    allContentfulClients {
      edges {
        node {
          name
          logo { file { url } }
          clientUrl
          industry
        }
      }
    }

    allContentfulPartners {
      edges {
        node {
          name
          logo { file { url } }
          industry
          partnerUrl
        }
      }
    }
  }
`;
